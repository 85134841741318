




























































































































































































































































































































































































































































































































































































































































































@use "~@stamhoofd/scss/base/variables.scss" as *;
@use '~@stamhoofd/scss/base/text-styles.scss';

.context-menu-container {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    // Allow dragging over the whole screen on touch devices
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;

    &.disableDismiss {
        pointer-events: none;

        > * {
            pointer-events: all;
        }
    }

    &.hasParent {
        pointer-events: none;

        .context-menu {
            pointer-events: auto;
        }
    }

    .context-menu {
        transform-origin: 0% 0%;
        transition: transform 0.2s;


        &.top {
            transform-origin: 0% 100%;
        }

        &.left {
            transform-origin: 100% 0%;

            &.top {
                transform-origin: 100% 100%;
            }
        }

        
    }

    &.show-enter-active,
    &.show-leave-active {
        transition: opacity 0.2s;
    }

    &.show-enter /* .fade-leave-active below version 2.1.8 */ {
        // Instant appearing context menu! (only leave animation)
        opacity: 0;

        .context-menu {
            transform: scale(0.8, 0.8);
        }
    }

    &.show-leave-to /* .fade-leave-active below version 2.1.8 */ {
        // Instant appearing context menu! (only leave animation)
        opacity: 0;

        .context-menu {
            transform: scale(0.8, 0.8);
        }
    }

    .triangle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: red;
        opacity: 0.5;
        pointer-events: none;
        z-index: 100000;
    }
}

.context-menu {
    position: fixed;
    z-index: 10000;

    background: $color-background-shade-darker;
    
    --color-current-background: #{$color-background-shade-darker};
    --color-current-background-shade: #{$color-border};

    border: $border-width-thin solid $color-border-shade;
    padding: 6px 15px;

    @extend .style-overlay-shadow;
    border-radius: $border-radius-modals;
    box-sizing: border-box;
    min-width: 200px;

    @media (max-width: 450px) {
        min-width: 70vw;
    }

    max-width: 100vw;
    max-width: calc(100vw - 30px);
    overflow: hidden;
    overflow-y: auto;

    // Hide carret in element below the context menu
    transform: translate3d(0, 0, 0);

    .context-menu-item {
        @extend .style-context-menu-item;
        cursor: pointer;
        user-select: none;
        touch-action: manipulation;
        display: flex;
        flex-direction: row;
        align-items: center;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        min-height: 32px;
        margin: 0 -9px;
        padding: 0 9px;
        border-radius: $border-radius;

        // Fix for button width
        width: 100%;
        text-align: left;
        box-sizing: content-box;

        @media (max-width: 450px) {
            min-height: 38px;
        }

        &.with-description {
            min-height: 42px;
        }

        .description {
            @extend .style-context-menu-item-description;
            padding-top: 3px;
        }

        > .left {
            &:empty {
                display: none;
            }
            flex-shrink: 0;
            padding-right: 10px;
        }

        > .middle {
            padding: 2px 0;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .icon:not(.arrow-right-small):not(.arrow-down-small):not(.arrow-up-small) {
            width: 20px;
            height: 20px;
            font-size: 20px;

            &:before {
                font-size: 20px; 
            }
        }

        > .right {
            &:empty {
                display: none;
            }
            margin-left: auto;
            flex-shrink: 0;
            padding-left: 20px;
        }

        /** Note we use a class, because :disabled also disables hover events */
        &.disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }

        &:not(.disabled) {
            &.isOpen {
                background: $color-gray-2;
            }

            &.hover {
                background: $color-primary;
                color: $color-primary-contrast;
            }

            &:active:not(.disable-active) {
                background: $color-primary;
                color: $color-primary-contrast;
            }
        }
    }

    .context-menu-line {
        background: $color-border-shade;
        border: 0;
        outline: 0;
        border-radius: $border-width-thin;
        height: $border-width-thin;
        margin: 6px 0;

        @media (max-width: 450px) {
            margin: 6px 0;
        }
    }
}
