























































@use "@stamhoofd/scss/base/variables.scss" as *;
@use "@stamhoofd/scss/base/text-styles.scss" as *;

.group-tree {
    padding-bottom: 20px;

    > h1 {
        padding-bottom: 15px;
        @extend .style-title-1;
    }

    > h2 {
        padding-bottom: 15px;
        @extend .style-title-2;
    }

    > p:not([class]) {
        @extend .style-description;
        padding-bottom: 40px;
    }

    > hr {
        @extend .style-hr;
    }

}
.group-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding-bottom: 20px;

    @media (max-width: 800px) {
        gap: 0;
        grid-template-columns: 1fr;

        > * {
            margin-bottom: 10px;
        }
    }
}

