


































































@use '~@stamhoofd/scss/base/variables' as *;
@use '~@stamhoofd/scss/base/text-styles' as *;

.steps-layout {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;

    > main {
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        box-sizing: border-box;
    }

    > .progress {
        position: fixed;
        left: 0;
        top: 0;
        height: 2px;
        pointer-events: none;
        background: $color-primary;
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        width: 0;
        opacity: 1;
        transition: width 0.3s, opacity 0.3s;
        z-index: 300;

        &.hide {
            transition: width 0.3s, opacity 0.2s 0.3s;
            opacity: 0;
        }
    }
}

