





























































































































































































.confirm-email-view {
    text-align: center;

    .email-illustration {
        width: 100px;
        height: 100px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
